import React from 'react'
import {Helmet} from 'react-helmet'
import CTA from 'components/call-to-action'
import Divider from 'components/divider'
import Header from 'components/header'
import Layout from 'layouts/layout'
import MapContainer from 'pages/contact/google-map'

import styles from 'pages/contact/contact.module.scss'

export default () => (
  <Layout>
    <Helmet>
      <title>
        Contact | Helios Underwriting
      </title>
    </Helmet>
    <main>
      <Header layout="standard" />
      <article className="o-content">
        <section className="padding-md">
          <h1 className="h1 lg:u-2/3">
            Contact
          </h1>
        </section>

        <section className="padding-md">
          <div className={styles.map}>
            <MapContainer />
          </div>
        </section>

        <section>
          <div className="c-contact">
            <div className="o-grid">
              <div className="o-grid__item">
                <h2 className="article-title mb-20">
                  London correspondence address:
                </h2>
                <Divider color="night" />
              </div>
              <div className="o-grid__item">
                <div className="c-contact__address">
                  <span className="u-bold">Helios Underwriting PLC</span>
                  <br />1st Floor
                  <br />33 Cornhill
                  <br />London
                  <br />United Kingdom
                  <br />EC3V 3ND
                </div>
              </div>
              <div className="o-grid__item">
                <div className="c-contact__address">
                  <span className="u-bold">Email</span>
                  <br />
                  <a className="u-color-steel" href="mailto:victoria.halman@huwplc.com">
                  victoria.halman@huwplc.com
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="c-contact">
            <div className="o-grid">
              <div className="o-grid__item">
                <h2 className="article-title mb-20">
                  Registered office
                </h2>
                <Divider color="night" />
              </div>
              <div className="o-grid__item">
                <div className="c-contact__address">
                  <span className="u-bold">Helios Underwriting PLC</span>
                  <br />1st Floor
                  <br />33 Cornhill
                  <br />London
                  <br />United Kingdom
                  <br />EC3V 3ND
                </div>
              </div>
              <div className="o-grid__item">
                <div className="c-contact__address">
                  <span className="u-bold">
                    Registered in England and Wales
                  </span>
                  <br /> Registered number 05892671
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="u-bg-whisper padding-md">
          <p>Helios Underwriting PLC is incorporated in England and Wales. The company’s main country of operation is the United Kingdom.</p>
        </section>

        <CTA
          title="About Helios Underwriting"
          link="/about-helios-underwriting/overview"
        />
      </article>

    </main>
  </Layout>
)
